// import { Container, AppBar, Typography, Grow, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { useDispatch } from 'react-redux';
import Books from './components/Books/Books'
import BookPage from './components/BookPage/BookPage'
import { getPosts } from './actions/posts';
import './App.css';

import { ThemeProvider, createTheme } from '@mui/material/styles'; 
const theme = createTheme({
  typography: {
    allVariants: {
      textAlign: "right",
    //   fontFamily: 'morvarid',
    //   textTransform: 'none',
    //   fontSize: 24,
    },
    h4:{
      fontFamily: 'morvarid',
      // textTransform: 'none',
      // fontSize: 24,
    },
    h3:{
      fontFamily: 'morvarid',
      textAlign: "center",
      // textTransform: 'none',
      fontSize: 30,
    }
  },
});

 

// const Sib = {
//     category: [
//       {
//         id: 10,
//         title: "فیزیک نوین و کلاسیک",
//       },
//       {
//         id: 20,
//         title: "نانوفناوری",
//       },
//       {
//         id: 30,
//         title: "الکترونیک و رباتیک",
//       },
//       {
//         id: 40,
//         title: "فناوری اطلاعات",
//       },
//       {
//         id: 50,
//         title: "هوانوردی و فضانوردی",
//       },
//     ],
//     books: [
//       {
//         id: 1005,
//         title: "اسکرچ",
//         lang: "فارسی",
//         desc: "آموزش زبان اسکرچ",
//         type: 1,
//         chap: [
//           "معرفی دوره",
//           "نصب",
//           "شروع کار",
//           "دستورات ساده 1",
//           "دستورات ساده 2",
//           "دستورات ساده 3",
//           "دستورات ساده 4",
//         ],
//         cat: [10],
//         tags: ["فیزیک موسیقی", "امواج"],
//       },
//       {
//         id: 1000,
//         title: "فیزیک ",
//         lang: "دو زبانه",
//         desc: "آموزش فیزیک و شیمی و ریاضی",
//         type: 1,
//         chap: ["test 1", "test2", "test 3"],
//         cat: [10],
//         tags: ["فیزیک موسیقی", "امواج"],
//       },
//       {
//         id: 1001,
//         title: "شیمی ",
//         lang: "دو زبانه",
//         desc: "آموزش فیزیک و شیمی و ریاضی",
//         type: 2,
//         chap: ["test 1", "test2", "test 3"],
//         cat: [20, 30],
//         tags: ["فیزیک موسیقی", "امواج"],
//       },
//       {
//         id: 1004,
//         title: "ریاضی ",
//         lang: "دو زبانه",
//         desc: "آموزش فیزیک و شیمی و ریاضی",
//         type: 3,
//         chap: ["test 1", "test2", "test 3"],
//         cat: [30],
//         tags: ["فیزیک موسیقی", "امواج"],
//       },
//     ],
//   };

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPosts());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
    <Router>
    {/* <Box sx={{ p: 1 }}> */}
      {/* <Navbar /> */}
      <Routes>
        <Route exact path="/" element={<Books />} />
        <Route path='/book-page/:id' element={<BookPage />} />
        {/* <Route path='/book-details/:id' component={VideoDetail} />
        <Route path='/search' component={SearchFeed} /> */}
      </Routes>
    {/* </Box> */}
  </Router>
  </ThemeProvider>

  );
}

export default App;
