import { Container, AppBar, Typography, Grow, Grid } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import Book from "./Book/Book";
// import { Image } from "semantic-ui-react";
import useStyles from "./styles";
import logo from "../../images/memories.png";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

const Books = () => {
  const { posts, isLoading } = useSelector((state) => state.posts);
  const classes = useStyles();

  const books = posts.books;
  const category = posts?.category;
  //   console.log("post:", posts)
  // if (!posts.length && !isLoading) return 'No posts';
  if (!books?.length && !isLoading) return "No posts";

  //   const category = sib.category;
  //   const books = sib.books;

  const CarouselItems = ({ id, books }) => {
    const book = books.filter((book) => book.cat.includes(id));
    return (
      <Carousel
      itemClass={classes.carouselItem}
        infinite
        // focusOnSelect={true}
        // containerClass="carousel-container-with-scrollbar"
        //   partialVisbile
        //   deviceType={deviceType}
        //   itemClass="image-item"
        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlay={false}
        // autoPlaySpeed={4000}
        // itemClass="carousel-item-padding-40-px"
        responsive={responsive}
      >
        {book.map((b, id) => {
          return <Book key={id} book={b} />;
        })}
      </Carousel>
    );
  };

  return (
    <Container maxWidth="lg">
      <AppBar className={classes.appBar} position="static" color="inherit">
        <Typography className={classes.heading} variant="h4" align="center">
          SIB: Smart Interactive Books
        </Typography>
        {/* <img className={classes.image} src={logo} alt="icon" height="60" /> */}
      </AppBar>
      <Grow in>
        <Container>
          {/* <Grid
            container
            justify="space-between"
            alignItems="stretch"
            spacing={3}
          > */}
          {/* <Grid item xs={12} sm={12}>
              <h1>Featured</h1>
            </Grid> */}
          {/* <Grid item xs={12} sm={12}> */}
          {/* <Form currentId={currentId} setCurrentId={setCurrentId} /> */}
          {category?.map((cat, index) => (
            <div className={classes.cat_title} key={index}>
              {/* <h1>{cat.title}</h1> */}
              <Typography
                className={classes.category_title}
                variant="h4"
                align="center"
              >
                {cat.title}
              </Typography>
              <CarouselItems id={cat.id} books={books} />
            </div>
          ))}
          {/* </Grid> */}
          {/* </Grid> */}
        </Container>
      </Grow>
    </Container>
  );
};

export default Books;
