import axios from 'axios';

// const API = axios.create({ baseURL: 'http://localhost:2094' });
const API = axios.create({ baseURL: 'https://api.daneshpark.org' });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }

  return req;
});

// export const fetchPost = (id) => API.get(`/posts/${id}`);
// export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
// export const fetchPostsByCreator = (name) => API.get(`/posts/creator?name=${name}`);
// export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
// export const createPost = (newPost) => API.post('/posts', newPost);
// export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
// export const comment = (value, id) => API.post(`/posts/${id}/commentPost`, { value });
// export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
// export const deletePost = (id) => API.delete(`/posts/${id}`);


// const Sib = {
//   category: [
//     {
//       id: 10,
//       title: "فیزیک نوین و کلاسیک",
//     },
//     {
//       id: 20,
//       title: "نانوفناوری",
//     },
//     {
//       id: 30,
//       title: "الکترونیک و رباتیک",
//     },
//     {
//       id: 40,
//       title: "فناوری اطلاعات",
//     },
//     {
//       id: 50,
//       title: "هوانوردی و فضانوردی",
//     },
//   ],
//   books: [
//     {
//       id: 1005,
//       title: "اسکرچ",
//       lang: "فارسی",
//       desc: "آموزش زبان اسکرچ",
//       type: 1,
//       chap: [
//         "معرفی دوره",
//         "نصب",
//         "شروع کار",
//         "دستورات ساده 1",
//         "دستورات ساده 2",
//         "دستورات ساده 3",
//         "دستورات ساده 4",
//       ],
//       cat: [10],
//       tags: ["فیزیک موسیقی", "امواج"],
//     },
//     {
//       id: 1000,
//       title: "فیزیک ",
//       lang: "دو زبانه",
//       desc: "آموزش فیزیک و شیمی و ریاضی",
//       type: 1,
//       chap: ["test 1", "test2", "test 3"],
//       cat: [10],
//       tags: ["فیزیک موسیقی", "امواج"],
//     },
//     {
//       id: 1001,
//       title: "شیمی ",
//       lang: "دو زبانه",
//       desc: "آموزش فیزیک و شیمی و ریاضی",
//       type: 2,
//       chap: ["test 1", "test2", "test 3"],
//       cat: [20, 30],
//       tags: ["فیزیک موسیقی", "امواج"],
//     },
//     {
//       id: 1004,
//       title: "ریاضی ",
//       lang: "دو زبانه",
//       desc: "آموزش فیزیک و شیمی و ریاضی",
//       type: 3,
//       chap: ["test 1", "test2", "test 3"],
//       cat: [30],
//       tags: ["فیزیک موسیقی", "امواج"],
//     },
//   ],
// };

export const fetchPost = (id) => API.get(`/sib/${id}`);
export const fetchPosts = (page) => API.get(`/sib`);
// export const fetchPost = (id) => {
//   const data = API.get(`/sib`);
//   console.log("dat:", data)
//   return (data);
// }

export const fetchPostsByCreator = (name) => API.get(`/posts/creator?name=${name}`);
export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
export const createPost = (newPost) => API.post('/posts', newPost);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const comment = (value, id) => API.post(`/posts/${id}/commentPost`, { value });
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);




