import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  carouselItem: {
    padding: "20px 0px",
    margin: "10px 0px",
  },
  appBar: {
    borderRadius: 15,
    margin: "30px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    color: "rgba(0,183,255, 1)",
  },
  image: {
    marginLeft: "15px",
  },
  cat_title: {
    marginTop: '20px',
  },
}));
