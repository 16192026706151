import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  CardActions,
  Collapse,
  Avatar,
  IconButton,
  CardContent,
  CardMedia,
  //   Button,
  Typography,
  //   red,
  ButtonBase,
} from "@mui/material/";

import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

import useStyles from "./styles";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({ book }) {
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();
  const navigate = useNavigate();

//   const img_url = `http://localhost:2094/assets/sib/${book.id}/cover.png`;
  const img_url = `https://api.daneshpark.org/assets/sib/${book.id}/cover.png`;
 
  //   const img_url = `http://daneshpark.pgu.ac.ir/sib/media/${book.id}/cover.png`;

  const type = ["V", "A", "P"];
  const Avatar_color = ["blue", "red", "green"];

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const openPost = (e) => {
    navigate(`/book-page/${book.id}`);
  };

  return (
    // <div className="card">
    <Card className={classes.card} sx={{ maxWidth: 345 }} raised elevation={6}>
      <ButtonBase
        component="span"
        name="test"
        className={classes.cardAction}
        onClick={openPost}
      >
        <CardHeader
          titleTypographyProps={{
            fontFamily: "morvarid",
            fontSize: 22,
            align: "right",
          }}
          subheaderTypographyProps={{
            fontSize: 10,
            variant: "h6",
            align: "center",
            // color: "secondary",
            // gutterBottom: true,
          }}
          avatar={
            <Avatar
            
              sx={{ bgcolor: Avatar_color[book.type - 1] }}
              aria-label="recipe"
            >
              {type[book.type - 1]}
            </Avatar>
          }
          // action={
          //   <IconButton aria-label="settings">
          //     <MoreVertIcon />
          //   </IconButton>
          // }
          title={book.title}
          subheader={book.lang}
        />
        <CardMedia
          component="img"
          height="194"
          image={img_url}
          alt="Paella dish"
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {book.desc}
          </Typography>
        </CardContent>
        {/* </Link> */}
      </ButtonBase>

      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* <Typography paragraph></Typography> */}
          {book.chap.map((chap, index) => {
            return (
              <Typography key={index} paragraph>
                {chap}
              </Typography>
            );
          })}
        </CardContent>
      </Collapse>
    </Card>
    // </div>
  );
}
